const categoriesList = [
  "new-products",
  "all-products",
  "apparel",
  "bags",
  "business",
  "drinkware",
  "headware",
  "key-rings",
  "leisure",
  "pens",
  "personal",
  "technology",
  "tools"
];

module.exports = () => {
  let list = [];

  categoriesList.forEach(category => {
    list.push({
      label: category,
      products: require('./' + category + '.json'),
    });
  });

  return list;
}
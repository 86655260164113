import React from "react"
import Layout from '../components/layout';
import Banner from '../components/banner';
import ContentBlock from '../components/contentblock';
import Categories from '../components/categories';
import { Helmet } from "react-helmet";

// IMAGES
import netballers from '../images/netballers.jpg';
import floatys from '../images/floatys.jpg';
import waterbottles from '../images/waterbottles.jpg';

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Home | Products By Design</title>
      {/* {<!-- Global site tag (gtag.js) - Google Analytics } */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-JBV7VK89HR"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-JBV7VK89HR');`}
      </script>
    </Helmet>
    <Banner
      title="We provide quality products personalised to your brand."
      description="Having trouble finding the right promotional products for your business? Let us find them for you, leaving you more time to focus on what you do best – meeting your clients’ needs."
      image={waterbottles}
      alignment="center 25%"
    />
    <Categories />
    <Banner
      title="Get a quote"
      description="Having trouble working out what the best possible product is going to fit your requirements? Let us here at Products by Design help you, just click on the Contact us button and let me reduce your concerns and I will get your project sorted!"
      button={{
        title: "Contact Us",
        url: "/contact"
      }}
      image={floatys}
    />
    <ContentBlock>
    <div className="container flex-container">
        <div className="content__col">
            <h3>Why use Personalised Products?</h3>
            <p>Customising products with your brand’s logo is a fantastic way to leave a lasting impression on your customers. Giving out these branded products will get your name out there, increase brand
            recognition, and promote your business.</p>

            <p>Why not give your employees a personalised gift to show your appreciation? There’s nothing that
            says thank you more than a gift for Christmas or a job well done.</p>

            <p>If you’re off to a trade show or promoting your business, these products are a useful external
            marketing tool. Everyone loves to get something for free!</p>
        </div>
        <div className="content__col">
            <h3>Why customers use Products By Design</h3>
            <ul>
                <li>Personalised service</li>
                <li>Quick turnaround</li>
                <li>We listen and make it happen</li>
                <li>Assistance from start to finish</li>
                <li>Free samples</li>
                <li>1,700+ products available to customise</li>
            </ul>
            <h3>When to use Personalised Products?</h3>
            <ul>
                <li>Conferences</li>
                <li>Employee gifts</li>
                <li>Trade shows</li>
                <li>Meetings</li>
                <li>Weddings</li>
                <li>Client gifts</li>
            </ul>
        </div>
      </div>
    </ContentBlock>
    <Banner
      image={netballers}
      alignment="50% 20%"
    />
  </Layout>
)

export default IndexPage

import React from 'react';
import { Link } from 'gatsby';

const Category = ({ label, product }) => {
  const image = `https://assets.trends.nz/Images/ProductImgSML/${product.Code}.jpg`;
  
  return (
    <Link to={`/category/${label}`}>
      <div className="product-preview">
        <img src={image} alt={`Category preview for ${label}`} />
        <div className="product-details">
          <div className="product-title">{label.replace('-', ' ')}</div>
          </div>
      </div>
    </Link>
  )
}

export default Category;

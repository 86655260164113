import React from "react";

const ContentBlock = (props) => {
  return (
    <div className="content__block">
        {props.children}
    </div>
  );
};


export default ContentBlock;

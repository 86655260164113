import React from 'react';
import Category from './category';
import LazyLoad from 'react-lazyload';
const getCategories = require('../data/products.js');

const Categories = () => {
  const categories = getCategories();
  return (
    <div className="products">
      <div className="container">
        <h2>Our Range</h2>
        <p>This is a small selection of what we do have available please get in touch by <a href="/contact/">sending us a message.</a></p>

        <div className="products-list">
          {categories.map((category) => {
            return (
              <div className="product-container" key={category['products'][0]['code']}>
                <LazyLoad>
                  <Category label={category.label} product={category['products'][0]}/>
                </LazyLoad>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Categories;